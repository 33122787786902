import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
// import Signin from "../views/Signin.vue";
import Login from "../views/Login.vue";
import MainPrivilage from "../views/MainPrivilage.vue";

// SYSTEM ADMINISTRATOR 
import Account from "../views/admin/Account.vue";
import Privileges from "../views/admin/Privileges.vue";
import Menu from "../views/admin/Menu.vue";
import SubMenu from "../views/admin/SubMenu.vue";
// DIHI PROCUREMNT
import DashboardProcurement from "../views/procurement/DashboardProcurement.vue";
import WorkOrderList from "../views/procurement/WorkOrderList.vue";
import VendorList from "../views/procurement/VendorList.vue";
import MaterialGroupPR from "../views/procurement/MaterialGroupPR.vue";
import ItemCategory from "../views/procurement/ItemCategory.vue";
import ItemSubCategory from "../views/procurement/ItemSubCategory.vue";
import ItemList from "../views/procurement/ItemList.vue";
import PurchasingRequisition from "../views/procurement/PurchasingRequisition.vue";
import RequestForQuotation from "../views/procurement/RequestForQuotation.vue";
import FormRfqVendor from "../views/procurement/FormRfqVendor.vue";
import ComparasionPrice from "../views/procurement/ComparasionPrice.vue";
import PurchaseOrder from "../views/procurement/PurchaseOrder.vue";
// HUMAN RESOURCE
import Employee from "../views/human_resource/Employee.vue";
import DetailProfile from "../views/human_resource/DetailProfile.vue";
import Attandance from "../views/human_resource/Attandance.vue";
import Payroll from "../views/human_resource/Payroll.vue";
import DetailSlip from "../views/human_resource/DetailSlip.vue";
import SettingOtAllIn from "../views/human_resource/SettingOtAllIn.vue";
import Setting from "../views/human_resource/Setting.vue";
import SettingShift from "../views/human_resource/SettingShift.vue";

import Plant from "../views/human_resource/Plant.vue";
import Department from "../views/human_resource/Department.vue";
import Position from "../views/human_resource/Position.vue";
import JobTitle from "../views/human_resource/JobTitle.vue";
// INVENTORY DIHI
import DashboardHse from "../views/inventory_hse/DashboardHse.vue";
import BarangMasuk from "../views/inventory_hse/BarangMasuk.vue";
import BarangMasukWr from "../views/inventory_hse/BarangMasukWr.vue";
import BarangKeluar from "../views/inventory_hse/BarangKeluar.vue";
import BarangKeluarWr from "../views/inventory_hse/BarangKeluarWr.vue";
import StockBarang from "../views/inventory_hse/StockBarang.vue";
import StockBarangWr from "../views/inventory_hse/StockBarangWr.vue";
import ReturnStock from "../views/inventory_hse/ReturnStock.vue";
import StockRemainder from "../views/inventory_hse/StockRemainder.vue";
import DetailBarang from "../views/inventory_hse/DetailBarang.vue";
// MASTER LIST PROJECT
import Customer from "../views/project_management/Customer.vue";
import ProjectList from "../views/master_list/ProjectList.vue";
import MasterListDetail from "../views/master_list/MasterListDetail.vue";

// INVENTORY WAREHOUSE
// import UnitOfMeasurement from "../views/inventory/UnitOfMeasurement.vue";
// import Supplier from "../views/inventory/Supplier.vue";
// import WarehouseLocation from "../views/inventory/WarehouseLocation.vue";
// import StorageLocation from "../views/inventory/StorageLocation.vue";
// import InventoryList from "../views/inventory/InventoryList.vue";
// import ItemsDetail from "../views/inventory/ItemsDetail.vue";
// import TransactionInventory from "../views/inventory/TransactionInventory.vue";
// import TransactionInventoryDetail from "../views/inventory/TransactionInventoryDetail.vue";
// import RemaindStock from "../views/inventory/RemaindStock.vue";
// import ReportByProject from "../views/inventory/ReportByProject.vue";
// import ReportByDate from "../views/inventory/ReportByDate.vue";
// import ReportByUser from "../views/inventory/ReportByUser.vue";
// MEDICAL REIMBUSHMENT
// import FrameAndLensGlasses from "../views/medical_reimbushment/FrameAndLensGlasses.vue";
// MEETING ROOM
// import MeetingSchedule from "../views/meeting_room/MeetingSchedule.vue";
// import ListMeetingRoom from "../views/meeting_room/ListMeetingRoom.vue";
// import MeetingRoomBooking from "../views/meeting_room/MeetingRoomBooking.vue";
// WORKING GROUP
import WorkingGroup from "../views/working_group/WorkingGroup.vue";
import WorkingGroupMembers from "../views/working_group/WorkingGroupMembers.vue";
// WORKING HOUR
import ActivityList from "../views/working_hour/ActivityList.vue";
import DailyActivity from "../views/working_hour/DailyActivity.vue";
import DailyControlBoard from "../views/working_hour/DailyControlBoard.vue";
import WhSummaryProjectActivity from "../views/working_hour/WhSummaryProjectActivity.vue";
import WhSummaryProjectJobTitle from "../views/working_hour/WhSummaryProjectJobTitle.vue";
import UserDailyActivity from "../views/working_hour/UserDailyActivity.vue";


// import DetailSlip from "../views/admin/DetailSlip.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/main-privilage",
  },


  // {
  //   path: '/detail-slip/:id_karyawan/:periode_start/:periode_end',
  //   name: 'DetailSlip',
  //   component: DetailSlip
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/main-privilage",
    name: "MainPrivilage",
    component: MainPrivilage,
  },

  // =================  SYSTEM ADMINISTRATOR  =========================
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/management-privileges",
    name: "Privileges",
    component: Privileges,
  },
  {
    path: "/management-menu",
    name: "Menu",
    component: Menu,
  },
  {
    path: "/management-sub-menu",
    name: "SubMenu",
    component: SubMenu,
  },
  // ================= PROCUREMENT =========================
  {
    path: "/dashboard-procurement",
    name: "DashboardProcurement",
    component: DashboardProcurement,
  },
  {
    path: "/work-order-list",
    name: "WorkOrderList",
    component: WorkOrderList,
  },
  {
    path: "/vendor-list",
    name: "VendorList",
    component: VendorList,
  },
  {
    path: "/material-group-pr",
    name: "MaterialGroupPR",
    component: MaterialGroupPR,
  },
  {
    path: "/proc-item-category",
    name: "ItemCategory",
    component: ItemCategory,
  },
  {
    path: "/proc-item-sub-category",
    name: "ItemSubCategory",
    component: ItemSubCategory,
  },
  {
    path: "/proc-item-list",
    name: "ItemList",
    component: ItemList,
  },
  {
    path: "/proc-pr",
    name: "PurchasingRequisition",
    component: PurchasingRequisition,
  },
  {
    path: "/proc-rfq",
    name: "RequestForQuotation",
    component: RequestForQuotation,
  },
  {
    path: "/proc-form-rfq-vendor/:code",
    name: "FormRfqVendor",
    component: FormRfqVendor,
  },
  {
    path: "/proc-cp",
    name: "ComparasionPrice",
    component: ComparasionPrice,
  },
  {
    path: "/proc-po",
    name: "PurchaseOrder",
    component: PurchaseOrder,
  },


  // ================= HUMAN RESOURCE =========================
  {
    path: "/management-employee",
    name: "Employee",
    component: Employee,
  },
  {
    path: '/detail-profile/:nik',
    name: 'DetailProfile',
    component: DetailProfile
  },
  {
    path: "/hr-attandance",
    name: "Attandance",
    component: Attandance,
  },
  {
    path: "/hr-payroll",
    name: "Payroll",
    component: Payroll,
  },
  {
    path: "/hr-payroll",
    name: "Payroll",
    component: Payroll,
  },
  {
    path: '/detail-slip/:id_karyawan/:periode_start/:periode_end',
    name: 'DetailSlip',
    component: DetailSlip
  },
  {
    path: "/setting-ot-all-in",
    name: "SettingOtAllIn",
    component: SettingOtAllIn,
  },
  {
    path: "/hr-setting-approval",
    name: "Setting",
    component: Setting,
  },
  {
    path: "/hr-setting-shift",
    name: "SettingShift",
    component: SettingShift,
  },

  {
    path: "/management-plant",
    name: "Plant",
    component: Plant,
  },
  {
    path: "/management-department",
    name: "Department",
    component: Department,
  },
  {
    path: "/management-position",
    name: "Position",
    component: Position,
  },
  {
    path: "/management-job-title",
    name: "JobTitle",
    component: JobTitle,
  },
  // ================= MASTER LIST PROJECT =========================
  {
    path: "/pm-customer",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/ml-project-list",
    name: "ProjectList",
    component: ProjectList,
  },
  {
    path: '/master-list-detail/:project_code',
    name: 'MasterListDetail',
    component: MasterListDetail
  },

  

  // ================= INVENTORY HSE =========================
  {
    path: "/dashboard-inv-hse",
    name: "DashboardHse",
    component: DashboardHse,
  },
  {
    path: "/barang-masuk",
    name: "BarangMasuk",
    component: BarangMasuk,
  },
  {
    path: "/barang-masuk-wr",
    name: "BarangMasukWr",
    component: BarangMasukWr,
  },
  {
    path: "/barang-keluar",
    name: "BarangKeluar",
    component: BarangKeluar,
  },
  {
    path: "/barang-keluar-wr",
    name: "BarangKeluarWr",
    component: BarangKeluarWr,
  },
  {
    path: "/stock-barang",
    name: "StockBarang",
    component: StockBarang,
  },
  {
    path: "/stock-barang-welding-rod",
    name: "StockBarangWr",
    component: StockBarangWr,
  },
  {
    path: "/hse-stock-return",
    name: "ReturnStock",
    component: ReturnStock,
  },
  {
    path: "/stock-remainder",
    name: "StockRemainder",
    component: StockRemainder,
  },
  {
    path: '/detail-barang/:material_code',
    name: 'DetailBarang',
    component: DetailBarang
  },
  // ================= WORKING GROUP =========================
  {
    path: "/wg-group",
    name: "WorkingGroup",
    component: WorkingGroup,
  },
  {
    path: "/wg-group-members",
    name: "WorkingGroupMembers",
    component: WorkingGroupMembers,
  },
  // ================= WORKING HOUR =========================
  {
    path: "/wh-activity-list",
    name: "ActivityList",
    component: ActivityList,
  },
  {
    path: "/wh-daily-activity",
    name: "DailyActivity",
    component: DailyActivity,
  },
  {
    path: "/wh-daily-control-board",
    name: "DailyControlBoard",
    component: DailyControlBoard,
  },
  {
    path: "/wh-summary-project-activity",
    name: "WhSummaryProjectActivity",
    component: WhSummaryProjectActivity,
  },
  {
    path: "/wh-summary-project-job-title",
    name: "WhSummaryProjectJobTitle",
    component: WhSummaryProjectJobTitle,
  },
  {
    path: "/wh-user-daily-activity",
    name: "UserDailyActivity",
    component: UserDailyActivity,
  },






// =====================================
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  // {
  //   path: "/signin",
  //   name: "Signin",
  //   component: Signin,
  // },
  
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const publicPages = ['Login','FormRfqVendor'];
  const authRequired = !publicPages.includes(to.name);
  const authenticated = JSON.parse(localStorage.getItem('authenticated'));  

  if (authRequired && !authenticated) {
      alert('Session Kamu Habis Ayo Login Lagi !!!')
      return next({
          name: 'Login',
          query: {redirect: to.fullPath}
      });
  }

  if (authenticated) {
      const auth = JSON.parse(authenticated);
      if (to.name == 'Login') {
          return next({
              name: 'MainPrivilage'
          });
      }
      if (to.name != 'Relogin') {
          if (auth.expired) {
            alert('Session Kamu Habis Ayo Login Lagi !!!')
            localStorage.removeItem('token');
            localStorage.setItem('authenticated', false)  
            return next({
                name: 'Login'
            });
          }
      }
  }
  next();
})

export default router;
